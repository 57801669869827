<template>
  <div class="info-header">
    <div class="box">
      <div class="icone-status" :class="{'is-checked-in': isStatusCheckIn, 'is-checked-out': isStatusCheckOut}">
        <template v-if="isStatusCheckIn">
          <i class="far fa-check-circle text-white"></i>
        </template>
        <template v-else-if="isStatusCheckOut">
          <i class="bx bx-log-out-circle text-white"></i>
        </template>
        <template v-else>
          <i class="far fa-question-circle text-white"></i>
        </template>
      </div>
      <div class="texts-box text-truncate">
        <div class="text-1 text-truncate">{{ name }}</div>
        <div class="text-2 text-truncate">{{ email }}</div>
        <div class="text-2 text-truncate"><button type="button" class="btn btn-outline-dark btn-sm" v-print="'#printMe'">Imprimir Etiqueta</button></div>
      </div>
    </div>
    <button type="button" class="close text-dark" @click="onClose">
      <img src="@/assets/images/icon/close.svg" alt="fechar"/>
    </button>
    <span style="display:none">
      <div id="printMe">
        <div class="qrcode">
          <img style="width:98px;margin-top: 5px" :src="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/eg_rsvp/qrcodes/${codId}.png`"/>
        </div>
        <div class="labelsprinter">
          <span class="nome-participante-no-barcode">{{ badge_name || name}}</span>
          <span class="segundo-campo-no-barcode">{{ school }}</span>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import print from 'vue-print-nb'

export default {
  name: 'info-header',
  props: {
    name: String,
    email: String,
    badge_name: String,
    school: String,
    codId: String,
    isStatusCheckIn: Boolean,
    isStatusCheckOut: Boolean,
    onClose: Function
  },
  directives: {
    print
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "src/design/variables";

.icone-status {
  width: 78px;
  height: 67px;
  border-radius: 7px;
  display: grid;
  place-items: center;
  background-color: #6B7280;
  font-size: 40px;
  margin-right: 12px;

  &.is-checked-in {
    background-color: #09A96F;
  }

  &.is-checked-out {
    background-color: #F1B44C;
  }
}

.texts-box {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  .text-1 {
    font-size: 20px;
    color: #05162B;
  }

  .text-2 {
    font-size: 14px;
    color: #05162B87;
  }
}

.info-header {
  --padding: 25px 20px;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --padding: 29px 34px;
  }

  display: flex;
  flex: 1;
  width: 100%;
  padding: var(--padding);
  .box {
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
  }
  .close {
    align-self: flex-start;
    opacity: 1;
  }
}
@page { size: landscape; margin: 0;}
#printMe {
  display: flex;
}
.labelsprinter{ /* plus .125 inches from padding */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-direction: column;
  height: 106px;
  width: 250px;
  border: 0 !important;
}
span {
  display: block;
}

.nome-participante-no-barcode{
  font-size: 20px;
  text-align: left;
  line-height: 18px;
  margin: 15px 0 0 5px;
  font-weight: bold;
}

.segundo-campo-no-barcode {
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  break-inside: avoid;
  margin: 10px 0 0 5px;
}
  @media print {
  body{
    margin:0;
    border: 0 !important;
  }
}
</style>
