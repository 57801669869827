<template>
  <div>
    <b-sidebar
      id="info-router"
      bg-variant="white"
      backdrop
      shadow
      lazy
      :visible="show"
      right
      @hidden="() => close()"
      width="512px"
      no-header
    >
      <template #default="{hide}">
        <info-header
          :name="item.name"
          :email="item.email"
          :badge_name="item.badge_name"
          :school="item.school"
          :codId="item.codId"
          :isStatusCheckIn="item.isStatusCheckIn"
          :isStatusCheckOut="item.isStatusCheckOut"
          :on-close="hide"
        />
        <b-tabs justified v-if="show" key="info-tabs">
          <b-tab title="Check-In">
            <div class="container-fluid mt-4">
              <info-check-in/>
            </div>
          </b-tab>
          <b-tab title="Detalhes">
            <div class="container-fluid mt-4">
              <info-detail/>
            </div>
          </b-tab>
        </b-tabs>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InfoHeader from '@/modules/check-in/pages/info/info-header'
import InfoCheckIn from '@/modules/check-in/pages/info/info-check-in/index'
import InfoDetail from '@/modules/check-in/pages/info/info-detail'
import { CHECK_IN_MODULE } from '@/modules/check-in'
import { mapActions } from 'vuex'

export default {
  name: 'info',
  components: { InfoDetail, InfoCheckIn, InfoHeader },
  methods: {
    ...mapActions(CHECK_IN_MODULE, ['fetchDetail']),
    close () {
      this.$store.commit(CHECK_IN_MODULE + '/setShowDetailId', null)
    }
  },
  computed: {
    currentId () {
      return this.$store.state[CHECK_IN_MODULE].showDetailId
    },
    show () {
      return !!this.currentId
    },
    item () {
      const item = this.$store.getters[CHECK_IN_MODULE + '/detailById'](this.currentId)
      if (!item) {
        return {
          isStatusCheckIn: false,
          isStatusCheckOut: false,
          name: '',
          badge_name: '',
          codId: '',
          school: '',
          email: ''
        }
      }
      return {
        isStatusCheckIn: item.isStatusCheckIn, isStatusCheckOut: item.isStatusCheckOut, name: item.name, badge_name: item.badge_name, school: item.school, codId: item.codId, email: item.email
      }
    }
  },
  watch: {
    currentId () {
      if (!this.currentId) {
        return
      }
      this.fetchDetail({ id: this.currentId })
    }
  }
}
</script>

<style scoped>

</style>
